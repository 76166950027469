
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'links',
    setup() {
      const { t, locale } = useI18n();
      return {
        t,
        locale,
      };
    },
  });
