
  import { defineComponent, computed } from 'vue';
  import {
    ionEllipse as defaultIcon,
    ionHeartCircleOutline as agreableIcon,
    ionHeartDislikeCircleOutline as disagreableIcon,
  } from '@quasar/extras/ionicons-v5';
  import { CHAKRA_STATUS } from '@/chakras';

  export default defineComponent({
    name: 'ChakraCircle',
    props: {
      size: {
        type: String,
        required: true,
      },
      status: {
        validator: (value: string) =>
          Object.keys(CHAKRA_STATUS).includes(value),
      },
    },
    setup(props) {
      return {
        icon: computed(() => {
          switch (props.status) {
            case CHAKRA_STATUS.AGREABLE:
              return agreableIcon;
            case CHAKRA_STATUS.DISAGREABLE:
              return disagreableIcon;
            default:
              return defaultIcon;
          }
        }),
      };
    },
  });
