const it = {
  language: 'Italiano',
  version: 'Versione',

  backlink: 'Ritorno',
  // Information pop-up
  popupTitle: 'Applicazione Test Colore',
  welcomeMessage: `            <p class="c-magenta">Benvenuti!</p>
        <p class="c-magenta">Tutto il nostro corpo energetico vibra su una gamma di frequenze che corrispondono allo spettro della luce bianca diffratta, lo spettro dei colori. Ad un certo livello, il nostro corpo energetico è come un arcobaleno. La nostra profonda risonanza reagisce tanto a un colore visibile dello spettro luminoso quanto a un colore invisibile al nostro sguardo.</p>
        <p class="c-purple">Ti offriamo questa App per scoprire il miglior metodo di utilizzazione dei colori per trovare i chakra bloccati, quelli che non funzionano correttamente e anche quelli che funzionano meglio. Questo metodo è stato sviluppato da Regina Martino nel 2009 per consentire ai suoi studenti di applicarlo alla litoterapia bioenergetica. La prima pubblicazione di questo test è stata nel libro "La Voie des Pierres et des Cristaux" pubblicato da AMBRE nel 2013.</p>
        <p class="c-cyan">Per iniziare a utilizzare l'App basta cliccare su "Test Colore" in basso a sinistra di questa schermata principale.</p>
        <p class="c-turquoise">Per saperne di più sui chakra, clicca sul link "Chakras" in basso a destra nella schermata principale. Scoprirai la loro posizione e le parole chiave ad esse associati.</p>
        <p class="c-pistachio">Il menu in alto a sinistra della pagina principale ti dà accesso alle FAQ dove c'è una spiegazione dettagliata di come eseguire il test e di come rendere l'App direttamente accessibile da un'icona sul tuo telefono cellulare (Android, iPhone).</p>
        <p></p>
        <p class="c-yellow">Questa schermata delle informazioni viene visualizzata automaticamente quando usi per la prima volta un browser o come applicazione indipendente. Per visualizzarlo di nuovo clicca sull'icona in alto a destra della schermata iniziale.</p>
        <p class="c-red">Buona esplorazione di colori e chakra!</p>`,
  close: 'Chiudere',

  // Header titles
  routeTitle: {
    Home: 'Test di Risonanza del Colore',
    Chakras: 'I Chakra',
    ColorTest: 'Test Colore',
    Faq: 'Domande Frequenti',
    Links: 'Link',
    Parameters: 'Impostazioni',
    Result: 'Risultato',
  },
  headerTitles: {
    home: 'Test di Risonanza del Colore',
    chakras: 'I Chakra',
    colortest: 'Test Colore',
    result: 'Risultato',
    notfound: 'Page inconnue',
  },

  // Home page
  homeTitle: 'Test di Risonanza del Colore',
  colorTest: 'Test Colore',
  chakrasLink: 'I Chakra',

  // Left Panel
  panelTitle: '@:menu',
  menu: 'Menù',
  faq: 'Domande frequenti',
  links: 'Link',
  parameters: 'Impostazioni',
  installTheApp: `Installa l'app`,

  // FAQ page
  faqTitle: '@:faq',
  methodTitle: 'Il metodo di risonanza del colore',
  methodContent: [
    `Tutto il nostro corpo energetico vibra su una gamma di frequenze che corrispondono allo spettro della luce bianca diffratta, allo spettro dei colori. Ad un certo livello, il nostro corpo energetico è come un arcobaleno. La nostra profonda risonanza reagisce tanto a un colore visibile dello spettro luminoso quanto a un colore invisibile al nostro sguardo.`,
    `Qui ti offriamo di scoprire il miglior metodo di utilizzazione dei colori per trovare i chakra bloccati, che non funzionano correttamente e anche quelli che funzionano meglio. Questo metodo è stato sviluppato da Regina Martino nel 2009 per consentire ai suoi studenti di applicarlo alla litoterapia bioenergetica. La prima pubblicazione di questo test è il libro "La Voie des Pierres et des Cristaux" pubblicato da AMBRE nel 2013.`,
  ],
  howToUseTitle: 'Come usare il test del colore',
  whyTheColorsTitle: 'Perché i colori?',
  whyTheColorsContent: [
    `I colori sono parte integrante della nostra vita e ci influenzano in modo consapevole o inconsapevole in ogni momento. I pubblicitari e i grafici hanno familiarità con questo linguaggio e sanno come far arrivare i loro messaggi in questo modo.`,
    `Uno dei principali interessi dell'utilizzo dei colori nella decodifica dei chakra è il loro carattere immediato e diretto. Non esiste un limite di età o di cultura per sapere come utilizzare il codice energetico dei colori. Se a un bambino di due anni viene mostrata una tavolozza di colori, non esiterà e indicherà rapidamente il suo colore preferito. L'atteggiamento del bambino è particolarmente giusto perché è pura spontaneità e le decisioni mentali o concettuali non sono ancora affermate. Ma potrete constatare, e lo spero, che sperimentare da soli, è ugualmente possibile anche per gli adulti.`,
  ],
  objectiveSubjectiveTitle: 'Percezione oggettiva e soggettiva',
  objectiveSubjectiveContent: [
    `Prima di tutto, è importante distinguere tra la percezione oggettiva di un colore (freddo, caldo, molto chiaro, leggero, scuro, lucido, opaco) e la percezione soggettiva (simpatia, antipatia, attrazione, avversione, sensazione di tensione, sensazione di rilassamento, ecc.). Per avere una decodifica energetica personale è essenziale utilizzare la nostra percezione soggettiva. È l'ascolto dell'emozione o l'effetto energetico che un colore ci procura in quel momento che ci permetterà di trovare una risposta in risonanza.`,
  ],
  howToTitle: 'Come fare?',
  howToContent: [
    `Sedetevi comodamente con la schiena dritta, mettetevi davanti allo schermo con gli 11 cerchi colorati, chiudete gli occhi per alcuni secondi, quindi fate un respiro profondo ed espirate (sempre con gli occhi chiusi).`,
    `Aprite gli occhi e guardate i colori uno per uno, nell'ordine spontaneo che vi appare. Lasciatevi penetrare da ciascuno di loro, uno alla volta.`,
    `Cercate 2 colori "negativi" che vi danno la sensazione più spiacevole (disgusto, tensione o fastidio) e fissateli per alcuni secondi evitando il giudizio.`,
    `Quindi cercate i 2 colori "positivi" che alleggeriscono questo fastidio.`,
    `Cliccando su “pollice in su” e “pollice in giù”, che troverete in alto a destra dello schermo, potrete selezionare rispettivamente i colori positivi e quelli negativi.`,
  ],
  colorMeaningTitle: 'Il significato dei colori positivi e negativi',
  colorMeaningContent: `Questo modo di procedere è il più semplice. I 2 colori "fastidiosi" sono quelli che corrispondono ai chakra dove si trova la problematica del momento, e quelli che alleviano, indicano i chakra che compensano le mancanze o i blocchi. Se dopo molti test il colore verso il quale si è attratti positivamente è sempre lo stesso, è molto probabile che corrisponda al chakra più grande o in eccesso.`,
  mentalInfluenceTitle: "L'influenza della mente",
  mentalInfluenceContent: `Attenzione! Cercate di limitare il funzionamento della vostra mente, i vostri concetti, i vostri giudizi. Se non riuscite subito, chiudete gli occhi e fate tre inspirazioni ed espirazioni profonde in successione, concentrandovi solo sul respiro. Allo stesso modo, quando guardate la combinazione di colori, non create un'associazione con un vestito o un oggetto che desiderate o detestate. Questo esercizio non è fatto per scegliere il colore del vostro prossimo acquisto di un abito o la tonalità di una pittura. Evitate anche di decidere mentalmente: "Mi è sempre piaciuto il blu!" o "il verde non mi si addice!"`,
  forIphoneTitle: 'Per iPhone',
  addToHomeTitle: `Aggiungi l'applicazione alla schermata principale (iPhone, iPad)`,
  addToHomeContent: [
    `L'installazione dell'applicazione su iPhone è possibile solo tramite Safari.`,
    {
      li: [
        `Apri il collegamento all'app in Safari.`,
        [`Cliccare sull'icona `, ` nella parte inferiore dello schermo.`],
        `Seleziona la riga "Nella schermata iniziale" `,
        `Fare clic su Aggiungi.`,
      ],
    },
  ],
  testDataTitle: 'Dati del test',
  dataCollectTitle: `L'App non raccoglie i dati.`,
  dataCollectContent: [
    `L'App non raccoglie i dati.`,
    `I test sono anonimi e non vengono inviate informazioni su Internet.`,
  ],

  // Parameters page
  languageTitle: 'Lingua',

  // Color Test page
  selectAThumb:
    'Selezionate un pollice (in su o in giù) per scegliere il tipo di colore',
  selectNegative: 'Selezionare 2 colori negativi',
  selectPositive: 'Selezionare 2 colori positivi',
  beforeSelecting:
    'Prima di selezionare un colore dovete scegliere il polllice in giù per i colori negativi o pollice in su per i colori positivi.',
  result: 'Risultato',
  reset: 'Reset',

  // Links page
  otherSites: 'Altri siti',

  // Result page
  resultTitle: '@:result',

  negativeColors: 'Colori Negativi',
  positiveColors: 'Colori Positivi',

  negativeMeaning:
    'I colori negativi indicano i chakra che sono in carenza nella vostra energia.',
  positiveMeaning:
    'I colori positivi indicano i chakra che sono in compensazione.',

  findNegativeWhen: 'Troviamo la frequenza {color} in negativo quando:',
  findPositiveWhen: 'Troviamo la frequenza {color} positiva quando:',

  newTest: 'Nuovo Test',
  home: 'Home',

  // Color names
  burgundy: 'bordeaux',
  red: 'rosso',
  orange: 'arancione',
  yellow: 'giallo',
  pistachio: 'pistacchio',
  green: 'verde',
  turquoise: 'turchese',
  cyan: 'ciano',
  blue: 'blu',
  indigo: 'indaco',
  purple: 'viola',
  magenta: 'magenta',

  frequence: {
    burgundy: 'bordeaux',
    red: 'rossa',
    orange: 'arancione',
    yellow: 'gialla',
    pistachio: 'pistacchio',
    green: 'verde',
    turquoise: 'turchese',
    cyan: 'ciano',
    blue: 'blu',
    indigo: 'indaco',
    purple: 'viola',
    magenta: 'magenta',
  },

  // Chakra page and result page
  chakraSystemTitle: 'Il Sistema dei Chakra',
  position: 'Posizione',
  keywords: 'Parole Chiave',

  chakras: require('@/lang/chakras/it.chakras.json'),

  // Chakra popup
  chakraPopup: [
    `I Chakra sono le porte principali del corpo energetico. Il loro nome deriva dal sanscrito e significa "ruote" o "dischi".`,
    `Sebbene la trasmissione di questa conoscenza ci provenga principalmente dall'India antica, la graduale diffusione dello Yoga e della conoscenza esoterica indiana, negli ultimi cento anni in Occidente, ha  consentito l'adozione di un sistema di 7 chakra principali in molte discipline energetiche. Il sistema che utilizziamo nasce da queste conoscenze ed è stato rivisto e adattato attraverso esperimenti e test in gruppi di ricerca sulla bioenergia che ho organizzato io stessa.`,
    `Fino ad oggi, e da più di 12 anni, utilizziamo un sistema interdipendente di 11 chakra, i 7 chakra principali più  4 chakra complementari. L'aggiunta di questi 4 chakra si è rivelata necessaria attraverso l'osservazione diretta del corpo energetico durante i nostri anni di studio e sperimentazione, in quanto consentono una maggiore precisione nella decodifica bioenergetica e quindi migliori risultati terapeutici. È questo sistema che verrà applicato qui e che potrete imparare e applicare.`,
    `Per facilitare l'applicazione di queste tecniche di litoterapia bioenergetica a principianti e iniziati, abbiamo scelto di chiamare i chakra con il nome dei loro colori di risonanza`,
  ],
};

export default it;
