import 'quasar/dist/quasar.css';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
//import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
//import '@quasar/extras/material-icons-round/material-icons-round.css';
//import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
//import '@quasar/extras/ionicons-v4/ionicons-v4.css';
//import '@quasar/extras/mdi-v4/mdi-v4.css';
//import '@quasar/extras/eva-icons/eva-icons.css';
import Meta from 'quasar/src/plugins/Meta.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: '#666666',
    },
  },
  plugins: { Meta, LocalStorage },
};
