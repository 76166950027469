import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createBlock(_component_q_expansion_item, {
    dark: "",
    class: "bg-grey-10",
    "header-class": "text-body1",
    label: _ctx.$props.label
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["label"]))
}