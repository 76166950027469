import { ref, Ref, computed } from 'vue';
import { BeforeInstallPromptEvent } from '@/types';
import Platform from 'quasar/src/plugins/Platform.js';;
import { useMainLocalStorage } from './local-storage-plugin';

const localStorage = useMainLocalStorage();

const deferredPrompt: Ref<BeforeInstallPromptEvent | null> = ref(null);
//    const os = ref(''); //ref(device.os);
const isChromium = 'onbeforeinstallprompt' in window;

export type SafariNavigator = Navigator & {
  standalone: boolean;
};
const myNavigator = navigator as SafariNavigator;

// Determine if the app is installed or running in a browser
const iOSNavigator = navigator as SafariNavigator;
const isStandalone = computed(() => {
  if (
    iOSNavigator.standalone ||
    window.matchMedia('(display-mode: standalone)').matches
  )
    return true;
  return false;
});

const showInstallIOS = ref(
  !isStandalone.value && !!Platform.is.ios && !!Platform.is.safari
);
const showInstallChromium = ref(
  !isStandalone.value &&
    isChromium &&
    (!!Platform.is.android || !!Platform.is.desktop)
);

const addA2hsEventListeners = (): void => {
  if (isChromium && !isStandalone.value) {
    // Take care of the custom prompt in Chromium based browsers if app not installed
    window.addEventListener('beforeinstallprompt', (e) => {
      showInstallChromium.value = true;
      e.preventDefault();
      deferredPrompt.value = e as BeforeInstallPromptEvent;
    });

    window.addEventListener('appinstalled', (evt) => {
      localStorage.setItem('appInstalled', true);
      console.log('Install SUCCESS!', evt);
    });
  }
};

const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) return 'twa';
  // eslint-disable-next-line no-prototype-builtins
  if (myNavigator.standalone !== undefined || isStandalone) return 'standalone';
  return 'browser';
};

const installApplicationChromium = () => {
  // install app on Chromium platform
  console.log('installApplicationChromium : ', deferredPrompt.value);
  if (deferredPrompt.value) {
    console.log(deferredPrompt.value);
    deferredPrompt.value.prompt();
    deferredPrompt.value.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        showInstallChromium.value = false;
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useA2hs = () => {
  return {
    isStandalone,
    showInstallIOS,
    showInstallChromium,

    addA2hsEventListeners,
    installApplicationChromium,
    getPWADisplayMode,
  };
};
