
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'MyExpansionItem',
    props: {
      label: {
        type: String,
        required: true,
      },
    },
  });
