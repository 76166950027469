
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useLocalStorage } from '@/plugins';

  export default defineComponent({
    name: 'parameters',
    setup() {
      const { t, locale } = useI18n();
      const localStorage = useLocalStorage();

      const changeLocale = () => {
        // i18n locale already changed
        // Store new locale in localStorage
        localStorage.setItem('locale', locale.value);
      };

      const localeOptions = [
        {
          label: 'Français',
          value: 'fr',
        },
        {
          label: 'Italiano',
          value: 'it',
        },
      ];

      return {
        t,
        locale,
        localeOptions,
        changeLocale,
      };
    },
  });
