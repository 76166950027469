import { App, inject, InjectionKey } from 'vue';

const localStorageKey = Symbol('LocalStorage') as InjectionKey<LocalStorage>;

const LS_NAME = 'ColorTest';
const LS_STORE_PARAM = 'parameters';
const LS_PREFIX = `${LS_NAME}/${LS_STORE_PARAM}/`;

interface LocalStorage {
  clear: () => void;
  removeItem: (key: string) => void;
  key: (index: number) => string | null;
  getItem: (key: string) => string | number | boolean | null;
  setItem: (key: string, value: string | number | boolean) => void;
  getAllKeys: () => string[];
  getAll: () => (string | boolean | number)[];
  isEmpty: () => boolean;
  getLength: () => number;
  has: (key: string) => boolean;
}

const _wrapKey = (key: string) => `${LS_NAME}/${LS_STORE_PARAM}/${key}`;
const _unwrapKey = <T extends string = string>(
  wrappedKey: string | null
): T | null => {
  return wrappedKey ? (wrappedKey.replace(LS_PREFIX, '') as T) : null;
};
const _unwrapItem = (item: string | null) => {
  if (item === null) return null;
  if (String(Number(item)) === item) return Number(item);
  if (item === 'true') return true;
  if (item === 'false') return false;
  return item.replaceAll(/^"|"$/g, '');
};

const getAllKeys = () => {
  const keys: string[] = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i) as string;
    if (key.startsWith(LS_PREFIX)) keys.push(key.replace(LS_PREFIX, ''));
  }
  return keys;
};

const getAll = () => {
  const keys = getAllKeys();
  const items: (string | number | boolean)[] = [];
  keys.forEach((key) => {
    items.push(
      _unwrapItem(window.localStorage.getItem(_wrapKey(key))) as
        | string
        | boolean
        | number
    );
  });
  return items;
};

const isEmpty = () => getAllKeys().length === 0;

const clear = () => {
  const keys = getAllKeys();
  keys.forEach((key) => window.localStorage.removeItem(_wrapKey(key)));
};

const getLength = () => {
  return getAllKeys().length;
};

const has = (key: string) => {
  return getAllKeys().includes(key);
};

const getItem = (key: string) => {
  return _unwrapItem(window.localStorage.getItem(_wrapKey(key)));
};

const removeItem = (key: string) =>
  window.localStorage.removeItem(_wrapKey(key));

const setItem = (key: string, value: string | number | boolean) => {
  window.localStorage.setItem(
    _wrapKey(key),
    typeof value === 'string' ? `"${value}"` : String(value)
  );
};

const key = (index: number) => _unwrapKey(window.localStorage.key(index));

const localStorage: LocalStorage = {
  clear,
  removeItem,
  getItem,
  key,
  setItem,
  getAllKeys,
  getAll,
  isEmpty,
  getLength,
  has,
};

interface LocalStoragePlugin {
  install: (app: App) => void;
}

export const createLocalStoragePlugin = (): LocalStoragePlugin => {
  const localStoragePlugin = {
    install: (app: App): void => {
      app.config.globalProperties.$MyLocalStorage = localStorage;
      app.provide(localStorageKey, localStorage);
    },
  };
  return localStoragePlugin;
};

export const useLocalStorage = (): LocalStorage => {
  return inject(localStorageKey) as LocalStorage;
};

const useMainLocalStorage = (): LocalStorage => {
  return localStorage;
};

export { useMainLocalStorage };
