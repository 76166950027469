import fr from './fr';
import it from './it';
// import en from './en';

const messages = {
  fr,
  it,
  //en,
};

export default messages;
