const env = process.env.NODE_ENV || 'development';

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import {
  createI18n,
  createGtm,
  createLogger,
  createLocalStoragePlugin,
  useMainLocalStorage,
} from '@/plugins';
import { createChakraStore } from './stores/chakras';

import '@/css/main.less';

import { createRamdaPlugin } from '@/plugins/ramda-vue';

const localStorage = useMainLocalStorage();

const userLocale = String(
  localStorage.getItem('locale') || navigator.language || 'fr'
).slice(0, 2);
const locale = ['fr', 'it'].includes(userLocale) ? userLocale : 'fr';

if (env === 'production')
  document
    .querySelector('[rel="manifest"]')
    ?.setAttribute('href', `/${locale}.manifest.json`);

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(createRamdaPlugin())
  .use(createChakraStore())
  .use(createI18n(locale))
  .use(createLogger())
  .use(createGtm())
  .use(createLocalStoragePlugin())
  .mount('#app');
