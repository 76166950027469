
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import Platform from 'quasar/src/plugins/Platform.js';;
  import MyExpansionItem from '@/components/MyExpansionItem.vue';

  const MY_CARD_CLASS = 'bg-grey-9';
  const MY_CARD_DARD = true;

  export default defineComponent({
    components: {
      MyExpansionItem,
    },
    name: 'faq',
    setup() {
      const { t } = useI18n();
      return {
        t,
        cardClass: MY_CARD_CLASS,
        dark: MY_CARD_DARD,
        Platform,
      };
    },
  });
