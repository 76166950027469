import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_my_expansion_item = _resolveComponent("my-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "bg-black" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_scroll_area, { class: "absolute-full" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_list, {
            bordered: "",
            class: "bg-grey-10 rounded-borders"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_my_expansion_item, {
                label: _ctx.t('methodTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('methodContent[0]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('methodContent[1]')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_label, {
            header: "",
            class: "text-subtitle1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('howToUseTitle')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_list, {
            bordered: "",
            class: "bg-grey-10 rounded-borders"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_my_expansion_item, {
                group: "howto",
                label: _ctx.t('whyTheColorsTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('whyTheColorsContent[0]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('whyTheColorsContent[1]')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_my_expansion_item, {
                group: "howto",
                label: _ctx.t('objectiveSubjectiveTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('objectiveSubjectiveContent[0]')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_my_expansion_item, {
                group: "howto",
                label: _ctx.t('howToTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('howToContent[0]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('howToContent[1]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('howToContent[2]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('howToContent[3]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('howToContent[4]')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_my_expansion_item, {
                group: "howto",
                label: _ctx.t('colorMeaningTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('colorMeaningContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_my_expansion_item, {
                group: "howto",
                label: _ctx.t('mentalInfluenceTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('mentalInfluenceContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_item_label, {
            header: "",
            class: "text-subtitle1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('testDataTitle')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_list, {
            bordered: "",
            class: "bg-grey-10 rounded-borders"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_my_expansion_item, {
                label: _ctx.t('dataCollectTitle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    dark: _ctx.dark,
                    class: _normalizeClass(_ctx.cardClass)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('dataCollectContent[0]')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('dataCollectContent[1]')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dark", "class"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          (_ctx.Platform.is.ios)
            ? (_openBlock(), _createBlock(_component_q_item_label, {
                key: 0,
                header: "",
                class: "text-subtitle1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('forIphoneTitle')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.Platform.is.ios)
            ? (_openBlock(), _createBlock(_component_q_list, {
                key: 1,
                bordered: "",
                class: "bordered-corners bg-grey-10"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_my_expansion_item, {
                    label: _ctx.t('addToHomeTitle')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card, {
                        dark: _ctx.dark,
                        class: _normalizeClass(_ctx.cardClass)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_card_section, null, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, _toDisplayString(_ctx.t('addToHomeContent[0]')), 1),
                              _createElementVNode("ol", null, [
                                _createElementVNode("li", null, _toDisplayString(_ctx.t('addToHomeContent[1].li[0]')), 1),
                                _createElementVNode("li", null, [
                                  _createTextVNode(_toDisplayString(_ctx.t('addToHomeContent[1].li[1][0]')), 1),
                                  _createVNode(_component_q_icon, { name: "ios_share" }),
                                  _createTextVNode(_toDisplayString(_ctx.t('addToHomeContent[1].li[1][1]')), 1)
                                ]),
                                _createElementVNode("li", null, _toDisplayString(_ctx.t('addToHomeContent[1].li[2]')), 1),
                                _createElementVNode("li", null, _toDisplayString(_ctx.t('addToHomeContent[1].li[3]')), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["dark", "class"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}