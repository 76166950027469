<template>
  <q-page class="home-container"> </q-page>
</template>
<style lang="less" scoped>
  .home-container {
    background: url('../assets/chakra-goddess.jpg') no-repeat center center /
      auto 100% local;
    background-color: #3e3e40;
    transition: all 0.3s ease-in;
  }
</style>
