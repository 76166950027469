export enum Color {
  burgundy,
  red,
  orange,
  yellow,
  pistachio,
  green,
  turquoise,
  cyan,
  blue,
  indigo,
  purple,
  magenta,
}

export enum ChakraStatus {
  AGREABLE,
  DISAGREABLE,
  NEUTRAL,
}
export enum CssColor {
  burgundy = '#9B2743',
  red = '#FF2A2B',
  orange = '#F67728', //"#FE5000",
  yellow = '#FFFF00', // "#FFD700",
  pistachio = '#97D700',
  green = '#008351', // "#007A33",
  turquoise = '#43BDB8', // "#64CCC9",
  cyan = '#0085CA',
  blue = '#0057B7',
  indigo = '#2F46BE',
  purple = '#7842C5', //"#440099",
  magenta = '#E10098', // "#E10098"
}

export enum ChakraType {
  primary = 'primary',
  secondary = 'secondary',
}
