import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_card = _resolveComponent("result-card")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "bg-black relative-position" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_scroll_area, { class: "absolute-full q-px-sm" }, {
        default: _withCtx(() => [
          _createVNode(_component_result_card, { type: "disagreable" }),
          _createVNode(_component_result_card, { type: "agreable" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}