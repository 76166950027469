
  import { defineComponent } from 'vue';
  import { useI18n } from '@/plugins';
  import ResultCard from '@/components/ResultCard.vue';

  export default defineComponent({
    name: 'Result',
    components: {
      ResultCard,
    },
    setup() {
      const { t } = useI18n();

      return {
        t,
      };
    },
  });
