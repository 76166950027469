
  import { useI18n, useRamda } from '@/plugins';
  import { defineComponent, ref, Ref, computed } from 'vue';
  import {
    useComputeIconSize,
    Patterns,
    PatternType,
    ContainerSize,
  } from '@/composables';
  import ChakraCircle from '@/components/ChakraCircle.vue';
  import { CHAKRA_STATUS } from '@/chakras';
  import { useChakraStore } from '@/stores/chakras';

  export default defineComponent({
    components: {
      ChakraCircle,
    },
    setup() {
      const { t } = useI18n();
      const { range } = useRamda();
      // Chakra Store related logic
      const store = useChakraStore();
      const {
        getChakra,
        getChakraId,
        getChakraStatus,
        toggleChakraStatus,
        chakraStatusCount,
      } = store;

      const chakrasAgreableCount = computed(() =>
        chakraStatusCount(CHAKRA_STATUS.AGREABLE)
      );
      const chakrasDisagreableCount = computed(() =>
        chakraStatusCount(CHAKRA_STATUS.DISAGREABLE)
      );

      // Toggle button related logic
      const thumbsUpDown = ref(CHAKRA_STATUS.DISAGREABLE);

      const choosing = computed(() => {
        return thumbsUpDown.value === CHAKRA_STATUS.AGREABLE
          ? 'selectPositive'
          : 'selectNegative';
      });

      const thumbsUpIcon = computed(() =>
        thumbsUpDown.value === CHAKRA_STATUS.AGREABLE
          ? 'fas fa-thumbs-up'
          : 'far fa-thumbs-up'
      );
      const thumbsDownIcon = computed(() =>
        thumbsUpDown.value === CHAKRA_STATUS.DISAGREABLE
          ? 'fas fa-thumbs-down'
          : 'far fa-thumbs-down'
      );

      const badgeColor =
        (countFunction: (s: string) => number) => (type: string) =>
          countFunction(type) < 2 ? 'negative' : 'positive';
      const thumbsBadgeColor = badgeColor(chakraStatusCount);

      // ChakraCircle related logic
      const pattern = ref(Patterns.portrait) as Ref<PatternType>;
      const { computeIconSize } = useComputeIconSize();
      const iconSize = ref('0px');

      const onResize = (size: ContainerSize) => {
        const computedSize = computeIconSize(size);
        iconSize.value = computedSize.size + 'px';
        pattern.value = computedSize.pattern;
      };

      const generateClasses = (index: number): string => {
        return `color-${index} c-${getChakra(index).colorName}`;
      };

      return {
        t,
        range,

        getChakraId,
        getChakraStatus,
        generateClasses,

        chakrasAgreableCount,
        chakrasDisagreableCount,

        thumbsUpDown,
        thumbsUpIcon,
        thumbsDownIcon,
        thumbsUpBadgeColor: computed(() =>
          thumbsBadgeColor(CHAKRA_STATUS.AGREABLE)
        ),
        thumbsDownBadgeColor: computed(() =>
          thumbsBadgeColor(CHAKRA_STATUS.DISAGREABLE)
        ),
        choosing,
        pattern,
        iconSize,

        // Mutations
        toggleChakraStatus,

        onResize,
      };
    },
  });
