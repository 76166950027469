import { createI18n as createVueI18nPlugin, useI18n } from 'vue-i18n';
import messages from '@/lang';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createI18n = (locale: string) =>
  createVueI18nPlugin({
    legacy: false,
    locale: locale,
    fallbackLocale: 'fr',
    messages,
  });

export { createI18n, useI18n };
