
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  //import { Store } from '@/composables';
  import { useChakraStore } from '@/stores/chakras';

  export default defineComponent({
    name: 'Chakras',
    setup() {
      const { t } = useI18n();
      const store = useChakraStore();

      return {
        t,
        chakras: store.chakras,
      };
    },
  });
