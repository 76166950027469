import { App } from 'vue';
import { chakraStoreKey } from './injectKey';
import chakraData from './data';
import { ref, computed, Ref } from 'vue';
import { Chakra, ChakraStatusType } from './types';
import { CHAKRA_STATUS } from './constants';
import { __, compose, equals, filter, lt, range, sortBy } from 'ramda';

interface ChakraStorePlugin {
  install: (app: App) => void;
}

// get a randomized array with values from 0 to 10
const random11 = () =>
  sortBy(() => 0.5 - Math.random(), range(0, 11)) as number[];

const chakras: Ref<Chakra[]> = ref(chakraData);
// initialize randomChakras list
const randomChakras = ref(random11());

const _chakraStatusFilter = (status: string) =>
  filter((chakra) => equals(chakra.status, status), chakras.value);

// count how many chakras of Status (agreable / disagreable)
const chakraStatusCount = (status: string) =>
  _chakraStatusFilter(status).length;

// array of agreable Chakras
const agreableChakras = computed(() =>
  _chakraStatusFilter(CHAKRA_STATUS.AGREABLE)
);
// array of disagreable Chakras
const disagreableChakras = computed(() =>
  _chakraStatusFilter(CHAKRA_STATUS.DISAGREABLE)
);

const chakrasAgreableCount = computed(() =>
  chakraStatusCount(CHAKRA_STATUS.AGREABLE)
);
const chakrasDisagreableCount = computed(() =>
  chakraStatusCount(CHAKRA_STATUS.DISAGREABLE)
);

const showResultButton = computed(
  () =>
    chakraStatusCount(CHAKRA_STATUS.AGREABLE) === 2 &&
    chakraStatusCount(CHAKRA_STATUS.DISAGREABLE) === 2
);
const showResetButton = computed(
  () =>
    chakraStatusCount(CHAKRA_STATUS.AGREABLE) > 0 ||
    chakraStatusCount(CHAKRA_STATUS.DISAGREABLE) > 0
);

// get Chakra using index from the randomChakras array
const getChakra = (index: number) => chakras.value[randomChakras.value[index]];
// get ID of Chakra from randomChakra at index
const getChakraId = (index: number) => getChakra(index).id;
// get status of random Chakra at index
const getChakraStatus = (index: number) => getChakra(index).status;
const setChakraStatus = (index: number, status: ChakraStatusType) =>
  (getChakra(index).status = status);
const resetChakraStatus = (index: number) =>
  setChakraStatus(index, CHAKRA_STATUS.NEUTRAL);

const addingStatusTypeIsAllowed = compose(lt(__, 2), chakraStatusCount);

// Mutations
const toggleChakraStatus = (index: number, statusType: string) => {
  if (getChakraStatus(index) === statusType) return resetChakraStatus(index);
  if (addingStatusTypeIsAllowed(statusType))
    return setChakraStatus(index, statusType as ChakraStatusType);
  return false;
};

const randomizeChakras = () => {
  randomChakras.value = random11();
};

const reset = (random = false) => {
  if (random) {
    randomizeChakras();
  }

  for (const index of randomChakras.value) {
    resetChakraStatus(index);
  }
};

const chakraStore = {
  chakras,
  agreableChakras,
  disagreableChakras,
  chakrasAgreableCount,
  chakrasDisagreableCount,

  showResetButton,
  showResultButton,

  randomChakras,

  getChakra,
  getChakraId,
  getChakraStatus,
  chakraStatusCount,

  // Mutations
  toggleChakraStatus,
  randomizeChakras,
  reset,
};

export type ChakraStore = typeof chakraStore;

export function createChakraStore(): ChakraStorePlugin {
  return {
    install: (app: App): void => {
      app.provide(chakraStoreKey, chakraStore);
    },
  };
}
