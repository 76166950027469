const LS_NAME = 'ColorTest';
const LS_STORE_PARAM = 'parameters';
const LS_PREFIX = `${LS_NAME}/${LS_STORE_PARAM}/`;

const keyWrapper = (key: string) => `${LS_NAME}/${LS_STORE_PARAM}/${key}`;
const keyUnwrapper = <T extends string = string>(
  wrappedKey: string | null
): T | null => {
  return wrappedKey ? (wrappedKey.replace(LS_PREFIX, '') as T) : null;
};
const unwrapItem = (item: string | null) => {
  if (item === null) return null;
  if (String(Number(item)) === item) return Number(item);
  if (item === 'true') return true;
  if (item === 'false') return false;
  return item.replaceAll(/^"|"$/g, '');
};

interface MyStorage {
  clear: () => void;
  removeItem: (key: string) => void;
  key: (index: number) => string | null;
  getItem: (key: string) => string | number | boolean | null;
  setItem: (key: string, value: string | number | boolean) => void;
  getAllKeys: () => string[];
  getAll: () => (string | boolean | number)[];
  isEmpty: () => boolean;
  getLength: () => number;
  has: (key: string) => boolean;
}

const getAllKeys = () => {
  const keys: string[] = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i) as string;
    if (key.startsWith(LS_PREFIX)) keys.push(key.replace(LS_PREFIX, ''));
  }
  return keys;
};

const getAll = () => {
  const keys = getAllKeys();
  const items: (string | number | boolean)[] = [];
  keys.forEach((key) => {
    items.push(
      unwrapItem(window.localStorage.getItem(keyWrapper(key))) as
        | string
        | boolean
        | number
    );
  });
  return items;
};

const isEmpty = () => getAllKeys().length === 0;

const clear = () => {
  const keys = getAllKeys();
  keys.forEach((key) => window.localStorage.removeItem(keyWrapper(key)));
};

const getLength = () => {
  return getAllKeys().length;
};

const has = (key: string) => {
  return getAllKeys().includes(key);
};

const getItem = (key: string) => {
  return unwrapItem(window.localStorage.getItem(keyWrapper(key)));
};

const localStorage: MyStorage = {
  clear,
  removeItem: (key: string) => window.localStorage.removeItem(keyWrapper(key)),
  getItem,
  key: (index: number) => keyUnwrapper(window.localStorage.key(index)),
  setItem: (key: string, value: string | number | boolean) => {
    window.localStorage.setItem(
      keyWrapper(key),
      typeof value === 'string' ? `"${value}"` : String(value)
    );
  },
  getAllKeys,
  getAll,
  isEmpty,
  getLength,
  has,
};

const useLocalStorage = (): MyStorage => {
  return localStorage;
};

export { useLocalStorage };
