
  import { computed, defineComponent } from 'vue';
  import { useChakraStore } from '@/stores/chakras';
  import { useI18n, useRamda } from '@/plugins';
  import { ionEllipse } from '@quasar/extras/ionicons-v5';

  export default defineComponent({
    name: 'ResultCard',
    props: {
      type: {
        type: String,
        required: true,
        validator: (value: string) =>
          ['agreable', 'disagreable'].includes(value),
      },
    },
    setup(props) {
      const { t } = useI18n();
      const { concat, toUpper, head, drop } = useRamda();
      const store = useChakraStore();
      const chakras =
        props.type === 'agreable'
          ? store.agreableChakras.value
          : store.disagreableChakras.value;
      const prefix = computed(() =>
        props.type === 'agreable' ? 'positive' : 'negative'
      );

      const capitalize = (value: string): string =>
        concat(toUpper(head(value)), drop(1, value));

      return {
        t,
        chakras,
        prefix,
        capitalize,
        ionEllipse,
      };
    },
  });
