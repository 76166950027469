import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "text-h6" }
const _hoisted_3 = { class: "text-subtitle2 text-italic text-blue-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "q-ma-md bg-grey-10 text-grey-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chakras, (chakra) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col text-center",
                key: chakra.id
              }, [
                _createVNode(_component_q_icon, {
                  name: _ctx.ionEllipse,
                  size: "100px",
                  class: _normalizeClass(chakra.color)
                }, null, 8, ["name", "class"])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t(`${_ctx.prefix}Colors`)), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t(`${_ctx.prefix}Meaning`)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator, { inset: "" }),
      _createVNode(_component_q_card_section, { class: "result-blocks__block__content q-gutter-md" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chakras, (chakra) => {
            return (_openBlock(), _createElementBlock("div", {
              key: chakra.id,
              class: "q-mb-sm"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([chakra.color, "q-pb-sm"])
              }, [
                _createVNode(_component_q_icon, {
                  name: _ctx.ionEllipse,
                  size: "16px",
                  left: "",
                  class: _normalizeClass(chakra.color)
                }, null, 8, ["name", "class"]),
                _createElementVNode("strong", null, _toDisplayString(_ctx.t(`find${_ctx.capitalize(_ctx.prefix)}When`, {
              color: _ctx.t('frequence.' + chakra.colorName),
            })), 1)
              ], 2),
              _createElementVNode("div", null, _toDisplayString(_ctx.t(chakra[`result${_ctx.capitalize(_ctx.prefix)}`])), 1)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}