import { App, inject, InjectionKey } from 'vue';
import * as R from 'ramda';

const ramdaKey = Symbol('Ramda') as InjectionKey<typeof R>;

interface Ramda {
  install: (app: App) => void;
}

export const createRamdaPlugin = (): Ramda => {
  const ramda = {
    install: (app: App): void => {
      app.config.globalProperties.$R = R;
      app.provide(ramdaKey, R);
    },
  };

  return ramda;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRamda = () => {
  return inject(ramdaKey) as typeof R;
};
