import { divide, find, gt, subtract, minBy, propSatisfies } from 'ramda';

export enum Patterns {
  landscape = 'landscape',
  portrait = 'portrait',
  square = 'square',
}

export type PatternType = keyof typeof Patterns;

type Ratio = {
  ratio: number;
  heightDivider: number;
  widthDivider: number;
  pattern: PatternType;
};

export type ContainerSize = {
  width: number;
  height: number;
};

type ComputeSize = (
  size: ContainerSize,
  options?: {
    ratios?: Ratio[];
    padding?: number;
  }
) => SizeType;

const defaultRatios: Ratio[] = [
  {
    ratio: 1,
    heightDivider: 3,
    widthDivider: 4,
    pattern: Patterns.landscape,
  },
  {
    ratio: 0.8,
    heightDivider: 4,
    widthDivider: 7 / 2,
    pattern: Patterns.square,
  },
  {
    ratio: 0,
    heightDivider: 5,
    widthDivider: 3,
    pattern: Patterns.portrait,
  },
];

interface SizeType {
  size: number;
  pattern: PatternType;
}

const computeIconSize: ComputeSize = (
  { height, width },
  { ratios = defaultRatios, padding = 0 } = {}
) => {
  const { heightDivider, widthDivider, pattern } = find(
    propSatisfies(gt(divide(width, height)), 'ratio'),
    ratios
  ) as Ratio;
  // ratios.find(({ ratio }) =>
  //  gt(divide(width, height), ratio)
  //) as Ratio;

  return {
    size: subtract(
      minBy(
        Math.floor,
        divide(height, heightDivider),
        divide(width, widthDivider)
      ),
      padding
    ),
    pattern: pattern as PatternType,
  };
};

export default function useComputeIconSize(): { computeIconSize: ComputeSize } {
  return {
    computeIconSize,
  };
}
