
  const env = process.env.NODE_ENV || 'development';
  import { ref, Ref, defineComponent, computed, provide } from 'vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useLogger } from 'vue-logger-plugin';
  import { useA2hs } from './plugins/a2hs-plugin';
  import { launchGtm } from './plugins';
  import useQuasar from 'quasar/src/composables/use-quasar.js';import useMeta from 'quasar/src/composables/use-meta.js';import morph from 'quasar/src/utils/morph.js';import QBtn from 'quasar/src/components/btn/QBtn.js';;
  import BackLinkButton from './components/MyBackLinkButton.vue';
  import { useChakraStore } from '@/stores/chakras';

  export default defineComponent({
    components: {
      BackLinkButton,
    },
    name: 'LayoutDefault',

    setup() {
      const route = useRoute();
      // Get t and locale from vueI18n plugin
      const { t, locale } = useI18n();
      // Get logger plugin
      const log = useLogger();
      // Get add to home screen plugin
      const {
        addA2hsEventListeners,
        installApplicationChromium,
        showInstallChromium,
      } = useA2hs();
      // addA2hs events
      addA2hsEventListeners();

      const q = useQuasar();

      /*** Managing the manifest and other meta tags ***/
      // Get the App name in preferred locale
      const title = computed(() => t('colorTest'));
      const manifestHref = computed(() => {
        if (env === 'production')
          return `${process.env.VUE_APP_PUBLIC_PATH}${locale.value}.manifest.json`;
        return '/manifest.json';
      });
      // adding meta tags
      useMeta(() => {
        return {
          title: title.value,
          link: {
            manifest: { rel: 'manifest', href: manifestHref.value },
          },
          meta: {
            appleMobileWebAppTitle: {
              name: 'apple-mobile-web-app-title',
              content: title.value,
            },
          },
        };
      });
      // removing old meta tags
      document.querySelector('[rel="manifest"]')?.remove();
      document.querySelector('[name="apple-mobile-web-app-title"]')?.remove();
      /* End of Managing manifest */

      /** Header Footer Drawer and Popups */
      const isHome = computed(() => route.name === 'Home');
      const isColorTest = computed(() => route.name === 'ColorTest');
      const isResult = computed(() => route.name === 'Result');
      const showFooter = computed(
        () => isHome.value || isColorTest.value || isResult.value
      );
      const footerHeight = ref(0);

      // Manage drawer and dialog status. closed by default
      const homePopupOpen = ref(false);
      provide('homePopupOpen', homePopupOpen);
      const chakraPopupOpen = ref(false);

      // Left Drawer
      const leftDrawerOpen = ref(false);
      const miniState = ref(false);
      const miniButtonRef = ref(null) as Ref<InstanceType<typeof QBtn> | null>;
      const miniButtonProps = computed(() => {
        return miniState.value
          ? {
              icon: 'fas fa-chevron-right',
            }
          : {
              icon: 'fas fa-chevron-left',
            };
      });
      let cancel:
        | { (): boolean; (abort?: boolean | undefined): boolean }
        | undefined;
      const morphMiniButton = function () {
        const onToggle = () => {
          miniState.value = !miniState.value;
        };
        if (cancel === void 0 || cancel() === false) {
          cancel = morph({
            from: miniButtonRef.value?.$el,
            onToggle,
            duration: 500,
            tween: true,
            tweenFromOpacity: 1,
            tweenToOpacity: 0,
            hideFromClone: true,
            onEnd: (end) => {
              end === 'from' && onToggle();
            },
          });
        }
      };
      /** End of Header Footer Drawer and Popups */

      // Manage ChakraStore
      const {
        reset,
        randomizeChakras: shuffleColors,
        showResetButton,
        showResultButton,
      } = useChakraStore();

      /** GTM Plugin related */

      homePopupOpen.value = launchGtm();

      /** End of GTM related */

      log.info(process.env.VUE_APP_GTM_ID);

      return {
        t,
        showFooter,
        isHome,
        isColorTest,
        isResult,
        homePopupOpen,
        chakraPopupOpen,
        leftDrawerOpen,
        miniState,
        miniButtonRef,
        miniButtonProps,
        morphMiniButton,
        showInstallChromium,
        installApplicationChromium,
        manifestHref,
        q,

        reset,
        shuffleColors,
        showResetButton,
        showResultButton,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onResize: (footerSize: any) => {
          footerHeight.value = footerSize.height;
        },
        pageStyle: (offset: number, height: number) => {
          const realOffset =
            50 + footerHeight.value - (showFooter.value ? 0 : 50);
          return {
            minHeight: `${height - realOffset}px`,
          };
        },
      };
    },
  });
