// https://openbase.com/js/vue-logger-plugin
import {
  createLogger as createVueLoggerPlugin,
  LoggerOptions,
  useLogger,
  VueLogger,
} from 'vue-logger-plugin';

const options: LoggerOptions = {
  enabled: true,
  level: process.env.VUE_APP_LOGGER_DEBUG_LEVEL,
};

// Create logger with options
const createLogger = (): VueLogger => createVueLoggerPlugin(options);
export { createLogger, useLogger };
