// import { useLogger } from '@/plugins';
import localforage from 'localforage';

const LS_NAME = 'ColorTest',
  LS_VERSION = 1,
  LS_STORE_PARAM = 'parameters';

const localForage = localforage.createInstance({
  name: LS_NAME,
  driver: localforage.LOCALSTORAGE,
  version: LS_VERSION,
  storeName: LS_STORE_PARAM,
});

const localForageJournal = localforage.createInstance({
  name: 'ColorTest',
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, localforage.WEBSQL],
  version: 1,
  storeName: 'journal',
});

const useLocalForage = (): LocalForage => {
  return localForage as LocalForage;
};

export default useLocalForage;
export { localForage, localForageJournal, LS_NAME, LS_STORE_PARAM };
