import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "bg-black" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_scroll_area, { class: "absolute-full" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_list, {
            bordered: "",
            class: "rounded-borders bg-grey-10 q-my-sm"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chakras, (chakra, index) => {
                return (_openBlock(), _createBlock(_component_q_expansion_item, {
                  "expand-separator": "",
                  "header-class": `c-${chakra.colorName} text-body1`,
                  key: index,
                  label: _ctx.t(chakra.name),
                  icon: "fas fa-circle",
                  group: "chakras",
                  dark: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card, { dark: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card_section, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('position')), 1),
                              _createTextVNode(" : " + _toDisplayString(_ctx.t(chakra.position)), 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('keywords')), 1),
                              _createTextVNode(" : " + _toDisplayString(_ctx.t(chakra.keywords)), 1)
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["header-class", "label"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}