import { BaseChakra, Chakra, ColorName } from './types';
import { CHAKRA_STATUS } from './constants';

function _i18nWrapper(key: string, colorName: ColorName): string {
  return 'chakras.' + colorName + '.' + key;
}

export const createChakra = ({ id, type, colorName }: BaseChakra): Chakra => ({
  id,
  type,
  colorName,
  status: CHAKRA_STATUS.NEUTRAL,

  name: _i18nWrapper('name', colorName),
  color: 'c-' + colorName,
  position: _i18nWrapper('position', colorName),
  keywords: _i18nWrapper('keywords', colorName),
  resultPositive: _i18nWrapper('resultPositive', colorName),
  resultNegative: _i18nWrapper('resultNegative', colorName),
});

export default createChakra;
