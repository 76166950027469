import {
  createGtm as createGtmPlugin,
  useGtm as useGtmPlugin,
} from '@gtm-support/vue-gtm';
import { reactive } from 'vue';
import { useMainLocalStorage } from './local-storage-plugin';
import { useA2hs } from './a2hs-plugin';
// import Platform from 'quasar/src/plugins/Platform.js';;

const localStorage = useMainLocalStorage();

export enum TC_Events {
  TC_FIRST_OPEN = 'tc_first_open',
  TC_FIRST_OPEN_INSTALLED = 'tc_first_open_installed',
  TC_LEGACY_INSTALLED = 'tc_legacy_installed',
  TC_LEGACY_UNINSTALLED = 'tc_legacy_uninstalled',
  TC_RECURRING_INSTALLED = 'tc_recurring_installed',
  TC_RECURRING_UNINSTALLED = 'tc_recurring_uninstalled',
  TC_RECURRING_NOT_COMPATIBLE = 'tc_recurring_not_compatible',
}

const options = {
  //  modify IDs in .env and .env.production files at root
  id: process.env.VUE_APP_GTM_ID,
  //queryParams: {},
  defer: false,
  enabled: false,
  debug: false,
  loadScript: true,
  // vueRouter: router,
  trackOnNextTick: false,
};

/*
const gtmEvent = reactive({
  event: '',
  isCompatible: true,
  platform: Platform.is.desktop
    ? 'desktop'
    : Platform.is.mobile
    ? 'mobile'
    : 'other',
  browser: Platform.is.name,
  os: Platform.is.platform,
  category: 'tc_event',
  action: 'click',
  label: 'label',
  value: 0,
  legacyUpdate: false,
});*/
const gtmEvent = reactive({
  event: '',
  category: 'tc_event',
  action: 'click',
  label: 'label',
  value: 0,
});

const gtmTrackEvent = (tcEventName: TC_Events): void => {
  const gtm = useGtmPlugin();
  if (gtm) {
    gtmEvent.event = tcEventName;
    gtm.enable(true);
    // console.log(gtm.options, gtmEvent);
    gtm.trackEvent(gtmEvent);
    gtm.enable(false);
  }
};

const { isStandalone } = useA2hs();

const legacyUpdate =
  window.localStorage.getItem('firstTime') !== null ||
  localStorage.has('recurringVisitor');

const launchGtm = (): boolean => {
  // console.log('launchGtm');
  const appInstalled = localStorage.getItem('appInstalled') as boolean | null;
  const recurring = localStorage.getItem('recurring') as boolean | null;

  // App is installed and used in standalone mode or recurring visitor
  // If it is already in standalone mode then set the appInstalled property too
  if (!localStorage.has('appInstalled') && isStandalone.value)
    localStorage.setItem('appInstalled', true);
  if ((appInstalled === true && isStandalone.value) || recurring) return false;
  // From now on it is only legacy or first time altogether
  // Set recurringVisitor property in localStorage for next time
  localStorage.setItem('recurring', true);
  // managing the events
  if (legacyUpdate) {
    // clear legacy property : firstTime
    window.localStorage.removeItem('firstTime');
    localStorage.removeItem('recurringVisitor');
    gtmTrackEvent(
      isStandalone.value
        ? TC_Events.TC_LEGACY_INSTALLED
        : TC_Events.TC_LEGACY_UNINSTALLED
    );
    return false;
  }
  // This is the first time opening the app
  gtmTrackEvent(TC_Events.TC_FIRST_OPEN); // Send appropriate event
  return true;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createGtm = () => createGtmPlugin(options);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGtm = () => {
  return { gtmTrackEvent, gtmEvent };
};

export { createGtm, useGtm, launchGtm };
