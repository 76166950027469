import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  Home,
  ColorTest,
  Result,
  Chakras,
  Faq,
  Links,
  Parameters,
} from '../views';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'scale-slide' },
  },
  {
    path: '/chakras',
    name: 'Chakras',
    component: Chakras,
    meta: { transition: 'scale-slide' },
  },
  {
    path: '/colortest',
    name: 'ColorTest',
    component: ColorTest,
    meta: { transition: 'scale-slide' },
  },
  {
    path: '/colortest/result/',
    name: 'Result',
    component: Result,
    meta: { transition: 'scale-slide' },
  },
  {
    path: '/faq/',
    name: 'Faq',
    component: Faq,
    meta: { transition: 'scale-slide' },
  },
  {
    path: '/links/',
    name: 'Links',
    component: Links,
    meta: { transition: 'fade' },
  },
  {
    path: '/parameters/',
    name: 'Parameters',
    component: Parameters,
    meta: { transition: 'fade' },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
});

export default router;
