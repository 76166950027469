import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  class: "absolute-full",
  style: {"margin-top":"50px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_chakra_circle = _resolveComponent("chakra-circle")!
  const _component_q_resize_observer = _resolveComponent("q-resize-observer")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "relative-position bg-black" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "bg-grey-8 text-grey-2" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, { class: "text-caption" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(_ctx.choosing)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_btn_toggle, {
            modelValue: _ctx.thumbsUpDown,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.thumbsUpDown) = $event)),
            color: "blue-grey-3",
            "toggle-color": "white",
            class: "no-padding",
            flat: "",
            ripple: false,
            dense: "",
            options: [
          {
            value: 'DISAGREABLE',
            slot: 'disagreable',
          },
          {
            value: 'AGREABLE',
            slot: 'agreable',
          },
        ]
          }, {
            disagreable: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_q_icon, { name: _ctx.thumbsDownIcon }, null, 8, ["name"]),
                _createVNode(_component_q_badge, {
                  color: _ctx.thumbsDownBadgeColor,
                  label: _ctx.chakrasDisagreableCount,
                  align: "top",
                  rounded: ""
                }, null, 8, ["color", "label"])
              ])
            ]),
            agreable: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_q_icon, { name: _ctx.thumbsUpIcon }, null, 8, ["name"]),
                _createVNode(_component_q_badge, {
                  color: _ctx.thumbsUpBadgeColor,
                  label: _ctx.chakrasAgreableCount,
                  align: "top",
                  rounded: ""
                }, null, 8, ["color", "label"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_TransitionGroup, {
          appear: "",
          class: _normalizeClass(["testcontainer", _ctx.pattern]),
          name: "fade",
          tag: "div"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.range(0, 11), (n) => {
              return (_openBlock(), _createBlock(_component_chakra_circle, {
                key: _ctx.getChakraId(n),
                status: _ctx.getChakraStatus(n),
                size: _ctx.iconSize,
                class: _normalizeClass(_ctx.generateClasses(n)),
                onClick: ($event: any) => (_ctx.toggleChakraStatus(n, _ctx.thumbsUpDown))
              }, null, 8, ["status", "size", "class", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_q_resize_observer, { onResize: _ctx.onResize }, null, 8, ["onResize"])
      ])
    ]),
    _: 1
  }))
}