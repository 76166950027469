export interface BaseChakra {
  id: number;
  type: ChakraType;
  colorName: ColorName;
}

export enum Color {
  burgundy,
  red,
  orange,
  yellow,
  pistachio,
  green,
  turquoise,
  cyan,
  blue,
  indigo,
  purple,
  magenta,
}

export enum ChakraStatus {
  AGREABLE,
  DISAGREABLE,
  NEUTRAL,
}

export type ChakraStatusType = keyof typeof ChakraStatus;

export type ColorName = keyof typeof Color;

export enum CssColor {
  burgundy = '#9B2743',
  red = '#FF2A2B',
  orange = '#F67728', //"#FE5000",
  yellow = '#FFFF00', // "#FFD700",
  pistachio = '#97D700',
  green = '#008351', // "#007A33",
  turquoise = '#43BDB8', // "#64CCC9",
  cyan = '#0085CA',
  blue = '#0057B7',
  indigo = '#2F46BE',
  purple = '#7842C5', //"#440099",
  magenta = '#E10098', // "#E10098"
}

export enum ChakraType {
  primary = 'primary',
  secondary = 'secondary',
}

// export type Color = keyof typeof ColorClass;

export const CHAKRA_STATUS = {
  AGREABLE: 'AGREABLE' as ChakraStatusType,
  DISAGREABLE: 'DISAGREABLE' as ChakraStatusType,
  NEUTRAL: 'NEUTRAL' as ChakraStatusType,
};

export class Chakra implements BaseChakra {
  id: number;
  type: ChakraType;
  colorName: ColorName;

  status: ChakraStatusType;

  constructor(chakra: BaseChakra) {
    const name = chakra.colorName;

    this.id = chakra.id;
    this.type = chakra.type;
    this.colorName = name;
    this.status = CHAKRA_STATUS.NEUTRAL;

    // TODO : compute name, color, position, keywords, resultPositive and resultNegative to match the position
    // OR make different locale.chakra.json files?
  }

  _i18nWrapper(key: string): string {
    return 'chakras.' + this.colorName + '.' + key;
  }

  get name(): string {
    return this._i18nWrapper('name');
  }

  get color(): string {
    return 'c-' + this.colorName;
  }

  get ionColorClass(): string {
    return `ion-color-${this.colorName}`;
  }

  get position(): string {
    return this._i18nWrapper('position');
  }

  get keywords(): string {
    return this._i18nWrapper('keywords');
  }

  get resultPositive(): string {
    return this._i18nWrapper('resultPositive');
  }

  get resultNegative(): string {
    return this._i18nWrapper('resultNegative');
  }
}

const data: BaseChakra[] = [
  {
    id: 0,
    type: ChakraType.secondary,
    colorName: 'burgundy',
  },
  {
    id: 1,
    type: ChakraType.primary,
    colorName: 'red',
  },
  {
    id: 2,
    type: ChakraType.primary,
    colorName: 'orange',
  },
  {
    id: 3,
    type: ChakraType.primary,
    colorName: 'yellow',
  },
  {
    id: 4,
    type: ChakraType.secondary,
    colorName: 'pistachio',
  },
  {
    id: 5,
    type: ChakraType.primary,
    colorName: 'green',
  },
  {
    id: 6,
    type: ChakraType.secondary,
    colorName: 'turquoise',
  },
  {
    id: 7,
    type: ChakraType.primary,
    colorName: 'cyan',
  },
  {
    id: 8,
    type: ChakraType.primary,
    colorName: 'indigo',
  },
  {
    id: 9,
    type: ChakraType.primary,
    colorName: 'purple',
  },
  {
    id: 10,
    type: ChakraType.secondary,
    colorName: 'magenta',
  },
];

const chakras: Chakra[] = JSON.parse(JSON.stringify(data), (key, value) => {
  if (typeof value === 'object' && value.type) {
    return new Chakra(value);
  }
  return value;
});

export default chakras;
