const fr = {
  language: 'Français',
  version: 'Version',

  backlink: 'Retour',

  // Information pop-up
  popupTitle: 'Application Test Couleur',
  welcomeMessage: `            <p class="c-magenta">Bienvenue!</p>
    <p class="c-magenta">Notre corps énergétique tout entier vibre sur une palette de fréquences qui correspondent au spectre de la lumière blanche diffractée, le spectre des couleurs. A un certain niveau, notre corps énergétique est comme un arc-en-ciel. Notre résonance profonde réagit aussi bien à une couleur visible du spectre lumineux, qu’à une couleur invisible à notre regard.</p>
    <p class="c-purple">Nous vous proposons avec cette App de découvrir la meilleure méthode d’utilisation des couleurs pour trouver les chakras bloqués, ceux qui ne fonctionnent pas correctement, et aussi ceux qui fonctionnent mieux. Cette méthode a été mise au point par Regina Martino en 2009 pour permettre à ses élèves de l’appliquer à la lithothérapie bioénergétique. La première publication de ce test s'est faite dans le livre « La Voie des Pierres et des Cristaux » publié chez AMBRE en 2013.</p>
    <p class="c-cyan">Pour commencer à utiliser l'application il vous suffit de cliquer sur "<em>Test Couleur</em>" en bas à gauche ce l'écran principal.</p>
    <p class="c-turquoise">Pour en savoir plus sur les chakras, cliquez sur le lien "<em>Chakras</em>" en bas à droite de l'écran principal. Vous découvrirez leur localisation et les mots clés qui leur sont associés.</p>
    <p class="c-pistachio">Le menu en haut à gauche de la page principale vous donne accès à la FAQ où se trouve une explication détaillée de la manière de faire le test, ainsi que comment rendre l'application directement accessible par une icône sur votre téléphone mobile (Android, iPhone).</p>
    <p></p>
    <p class="c-yellow">Cet écran d'information s'affiche automatiquement lors de la première utilisations sur un navigateur ou en tant qu'application indépendante. Pour l'afficher à nouveau cliquer sur l'icône <f7-link icon-ios="f7:info_circle" icon-aurora="f7:info_circle" icon-md="material:info"></f7-link> en haut à droite de l'écran d'accueil.</p>
    <p class="c-red">Bonne exploration des couleurs et des chakras!</p>`,
  close: 'Fermer',

  // Header titles
  routeTitle: {
    Home: 'Test Résonance Couleurs',
    Chakras: 'Chakras',
    ColorTest: 'Test Couleur',
    Faq: 'Questions Fréquentes',
    Links: 'Liens Utiles',
    Parameters: 'Paramètres',
    Result: 'Résultat',
  },
  headerTitles: {
    home: 'Elixirs Minéraux',
    elixirs: 'Elixirs Minéraux',
    chakras: 'Chakras',
    colortest: 'Test Couleur',
    result: 'Résultat',
    notfound: 'Page inconnue',
  },
  homeTitle: 'Test Résonance Couleurs / Chakras',
  colorTest: 'Test Couleur',
  chakrasLink: 'Chakras',

  // Left Panel
  panelTitle: '@:menu',
  menu: 'Menu',
  faq: 'Pour en savoir plus',
  links: 'Liens utiles',
  parameters: 'Paramètres',
  installTheApp: `Installer l'application`,

  // FAQ page
  faqTitle: 'Pour en savoir plus',
  methodTitle: 'La méthode Résonance Couleur',
  methodContent: [
    `Notre corps énergétique tout entier vibre sur une palette de fréquences qui correspondent au spectre de la lumière blanche diffractée, le spectre des couleurs. A un certain niveau, notre corps énergétique est comme un arc-en-ciel. Notre résonance profonde réagit aussi bien à une couleur visible du spectre lumineux, qu’à une couleur invisible à notre regard.`,
    `Nous vous proposons ici de découvrir la meilleure méthode d’utilisation des couleurs pour trouver les chakras bloqués, qui ne fonctionnent pas correctement et aussi ceux qui fonctionnent mieux. Cette méthode a été mise au point par Regina Martino en 2009 pour permettre à ses élèves de l’appliquer à la lithothérapie bioénergétique. La première publication de ce test est le livre « La Voie des Pierres et des Cristaux » publié chez AMBRE en 2013.`,
  ],
  howToUseTitle: 'Comment utiliser le Test Couleur',
  whyTheColorsTitle: 'Pourquoi les couleurs ?',
  whyTheColorsContent: [
    `Les couleurs font partie intégrante de notre vie et nous influencent à chaque instant de façon consciente et inconsciente. Les publicitaires et les graphistes connaissent bien ce langage et savent faire passer leurs messages de cette façon.`,
    `L’un des principaux intérêts d’utiliser les couleurs dans le décodage des chakras est leur caractère immédiat et direct. Il n’y a pas de limite d’âge ou de culture pour savoir utiliser le code énergétique des couleurs. Si l’on montre une palette de couleurs à un enfant de deux ans, il n’hésitera pas et indiquera très vite sa couleur préférée. L’attitude de l’enfant est particulièrement juste car elle est pure spontanéité, et les décisions mentales ou conceptuelles ne sont pas encore affirmées. Mais vous allez constater, et je l’espère, expérimenter par vous-mêmes, que c’est également possible pour les adultes.`,
  ],
  objectiveSubjectiveTitle: 'Perception objective et subjective?',
  objectiveSubjectiveContent: [
    `Il est tout d’abord important de faire la différence entre la perception objective d’une couleur (froid, chaud, fort léger, clair, foncé, brillant, terne) et la perception subjective (sympathie, antipathie, attirance, aversion, émotion de tension, émotion de détente, etc). Pour avoir un décodage énergétique personnel il est primordial d’utiliser notre perception subjective. C’est l’écoute de l’émotion ou l’effet énergétique qu’une couleur nous procure dans l’instant qui permettra de trouver une réponse en résonance.`,
  ],
  howToTitle: 'Comment faire ?',
  howToContent: [
    `Asseyez-vous confortablement avec le dos droit, mettez-vous devant l’écran des 11 ronds de couleur, fermez les yeux pendant quelques secondes, puis prenez une inspiration et une expiration profondes (toujours les yeux fermés).`,
    `Ouvrez les yeux et regardez les couleurs une à une, dans l’ordre spontané qui vous apparait. Laissez-vous pénétrer par chacune d’elle, une à la fois.`,
    `Cherchez 2 couleurs "négatives" qui vous donnent la sensation la plus désagréable (un dégoût, une simple tension ou un agacement), et fixez-la quelques secondes en évitant tout jugement.`,
    `Puis cherchez les 2 couleurs "positives" qui allègent au mieux cet agacement.`,
    `En cliquant sur les pouces vers le haut et vers le bas en haut à droite de l'écran, vous pourrez sélectionner respectivement les couleurs positives et les couleurs négatives.`,
  ],
  colorMeaningTitle: 'La signification des couleurs positives et négatives ?',
  colorMeaningContent: `Cette façon de procéder est la plus simple. Les 2 couleur « agaçantes » sont celles qui correspondent aux chakras où se situe la problématique du moment, et celles qui soulagent sont celles des chakras qui compensent les manques ou les blocages. Si après des nombreux test la couleur vers laquelle on est positivement attiré est toujours la même, il est fort probable qu’elle corresponde au chakra le plus grand voir en excès.`,
  mentalInfluenceTitle: "L'influence du mental ?",
  mentalInfluenceContent: `Attention ! : Essayez de limiter le fonctionnement de votre esprit mental, vos concepts, vos jugements. Si vous n’y arrivez pas de suite, refermez les yeux et prenez successivement trois inspirations et expirations profondes en ne portant votre attention que sur la respiration. De même, lorsque vous poserez les yeux sur le schéma des couleurs, ne faites pas d’association avec un vêtement ou un objet que vous désirez ou que vous détestez. Cet exercice n’est pas fait pour choisir la couleur de votre prochain achat de vêtement ou la teinte d’une peinture. Evitez également de décider mentalement : « j’ai toujours aimé le bleu ! » ou « le vert ne me va pas ! ».`,
  forIphoneTitle: 'Pour iPhone',
  addToHomeTitle: `Ajouter l'application sur l'écran d'accueil (iPhone, iPad)`,
  addToHomeContent: [
    `L'installation de l'application sur iPhone est possible uniquement en passant par Safari.`,
    {
      li: [
        `Ouvrez le lien vers l’application dans Safari.`,
        [`Cliquez sur l’icône `, ` en bas de l’écran.`],
        `Sélectionnez la ligne “Sur l’écran d’accueil”`,
        `Cliquez sur Ajouter.`,
      ],
    },
  ],
  testDataTitle: 'Les données des tests',
  dataCollectTitle: `L'application ne fait pas de collecte de donnée.`,
  dataCollectContent: [
    `L'application ne fait pas de collecte de données.`,
    `Les tests sont anonymes et aucune information n'est envoyée sur internet.`,
  ],

  // Parameters page
  languageTitle: 'Langue',

  // Color Test page
  selectAThumb: 'Sélectionnez un pouce pour choisir le type de couleurs',
  selectNegative: 'Sélectionnez 2 couleurs negatives',
  selectPositive: 'Sélectionnez 2 couleurs positives',
  beforeSelecting:
    'Avant de sélectionner une couleur vous devez choisir le pouce vers le bas pour les couleurs négatives ou le pouce vers le haut pour les couleurs positives.',
  result: 'Résultat',
  reset: 'Reset',

  // Links page
  otherSites: 'Autres sites',

  // Result page
  resultTitle: '@:result',

  negativeColors: 'Couleurs Negatives',
  positiveColors: 'Couleurs Positives',

  negativeMeaning:
    'Les couleurs négatives indiquent les chakras qui font défaut dans votre énergie.',
  positiveMeaning:
    'Les couleurs positives indiquent les chakras qui compensent.',

  findNegativeWhen: 'Nous trouvons la fréquence {color} en négatif quand :',
  findPositiveWhen: 'Nous trouvons la fréquence {color} en positif quand :',

  newTest: 'Nouveau Test',
  home: 'Accueil',

  primary: 'primaire',
  secondary: 'secondaire',

  // Color names
  burgundy: 'bordeaux',
  red: 'rouge',
  orange: 'orange',
  yellow: 'jaune',
  pistachio: 'pistache',
  green: 'vert',
  turquoise: 'turquoise',
  cyan: 'cyan',
  blue: 'bleu',
  indigo: 'indigo',
  purple: 'violet',
  magenta: 'magenta',

  frequence: {
    burgundy: 'bordeaux',
    red: 'rouge',
    orange: 'orange',
    yellow: 'jaune',
    pistachio: 'pistache',
    green: 'vert',
    turquoise: 'turquoise',
    cyan: 'cyan',
    blue: 'bleu',
    indigo: 'indigo',
    purple: 'violet',
    magenta: 'magenta',
  },

  // Chakra page and result page
  chakraSystemTitle: 'Le Système des Chakras',

  position: 'Position',
  keywords: 'Mots Clés',

  chakras: require('@/lang/chakras/fr.chakras.json'),

  // Chakra popup
  chakraPopup: [
    `Les Chakras sont les principales portes du corps énergétique. Leur nom vient du sanskrit et signifie « roues » ou « disques ».`,
    `Bien que la transmission de cette connaissance nous vienne principalement de l’Inde ancienne, la diffusion progressive des Yogas et de la connaissance ésotérique indienne depuis une centaine d’années en Occident a permis l’adoption d’un système à 7 chakras principaux dans de nombreuses disciplines énergétiques. Le système que nous utilisons provient de ces connaissances et a été revu et adapté à travers des expériences et des tests dans des groupes de recherche en bioénergie que j’ai moi-même organisés.`,
    `Nous utilisons à ce jour, et depuis plus de 12 ans, un système interdépendant de 11 chakras, les 7 principaux plus 4 chakras complémentaires. L’ajout de ces 4 chakras s’est avéré nécessaire par l’observation directe du corps énergétique lors de nos années d’études et de tests, car ils permettent une plus grande précision du décodage bioénergétique et par conséquent de meilleurs résultats thérapeutiques. C’est ce système qui sera appliqué ici et que vous pourrez apprendre et appliquer.`,
    `Nous avons choisi, pour faciliter l’application de ces techniques de lithothérapie bioénergétique aux novices comme aux initiés, d’appeler les chakras avec le nom de leurs couleurs de résonance.`,
  ],
};

export default fr;
