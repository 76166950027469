import { BaseChakra, Chakra } from './types';
import { ChakraType } from './enums';
import createChakra from './chakra';

const data: BaseChakra[] = [
  {
    id: 0,
    type: ChakraType.secondary,
    colorName: 'burgundy',
  },
  {
    id: 1,
    type: ChakraType.primary,
    colorName: 'red',
  },
  {
    id: 2,
    type: ChakraType.primary,
    colorName: 'orange',
  },
  {
    id: 3,
    type: ChakraType.primary,
    colorName: 'yellow',
  },
  {
    id: 4,
    type: ChakraType.secondary,
    colorName: 'pistachio',
  },
  {
    id: 5,
    type: ChakraType.primary,
    colorName: 'green',
  },
  {
    id: 6,
    type: ChakraType.secondary,
    colorName: 'turquoise',
  },
  {
    id: 7,
    type: ChakraType.primary,
    colorName: 'cyan',
  },
  {
    id: 8,
    type: ChakraType.primary,
    colorName: 'indigo',
  },
  {
    id: 9,
    type: ChakraType.primary,
    colorName: 'purple',
  },
  {
    id: 10,
    type: ChakraType.secondary,
    colorName: 'magenta',
  },
];

export const chakras: Chakra[] = data.map((chakra) => createChakra(chakra));

export default chakras;
